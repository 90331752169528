export default {

  company_name: "CROSS BORDER TRADE INTEGRATED SERVICE CO., LIMITED",
  t0: "Home",
  t1: "A compliant, professional, and reliable cross-border payment system enables customers with one-stop business operating solutions. By integrating with the service provider system, the financial institutions will get valuable customers and the customers will enjoy personalized cross-border comprehensive financial services featuring cross-border supply chain finance.",
  t2: "Enter your email address",
  t3: "Register",
  t4: "Cross-border Service Provider System",
  t5: "Cross-border Payment System",
  t6: "Cross-border Supply Chain Finance",
  t7: "Global Acquiring",
  t8: "Multiple Digital Wallets",
  t9: "Advantages",
  t10: "Local Payment",
  t11: "Local Digital Wallets in 10+ countries/regions",
  t12: "Multiple Currencies",
  t13: "10+ currencies such as CNY, HKD, KWY, PHP, and IDR",
  t14: "Flexible Integration",
  t15: "Rapid API integration and efficient operating interface",
  t16: "Global Accounts",
  t17: "Global Collection",
  t18: "Accept local payments in mainstream countries/regions such as Britain, the European Union, and the United States, and global SWIFT payments",
  t19: "Global Transfer",
  t20: "Support prevailing local payment methods such as UKFAST, FEDWIRE, and SEPA",
  t21: "Multiple Currencies Accounts",
  t22: "Cooperate with global well-known banks and institutions, and provide 10+ currencies accounts",
  t23: "FX Settlement & Withdrawal",
  t24: "Cooperate with domestic banks and licensed institutions, and declare compliance",
  t25: "Global Marketplace",
  t26: "A professional and reliable selling and buying service platform for both sellers and buyers",
  t27: "About Us",
  t28: "Who we are",
  t29: "CBTIS aims to build a cross-border trade comprehensive service platform integrating Cross-Border Trade Consultancy, Company Secretary, System Construction, Procurement & Sales of Goods, Advertising, Warehousing, Logistics and Customs Clearance, Global Acquiring, and Global Accounts, and Supply Chain Finance. We have extensive experience in cross-border payment and trade service operations, providing professional, rapid, safe, and reliable comprehensive solutions for various scenarios such as cross-border e-commerce, cross-border logistics, social tourism, short video, games, etc.",
  t30: "Our Values",
  t31: "Empathy Coexistence Professionalism Reliability",
  t32: "Our Mission",
  t33: "Committed to becoming the world's leading professional and reliable cross-border trade comprehensive service platform",
  t34: "Our Purpose",
  t35: "By building a high-quality cross-border trade service provider system, we provide customers with professional and reliable one-stop cross-border trade comprehensive services. By building an efficient and secure cross-border payment system, we provide customers with compliant, professional, and reliable one-stop business operation solutions. Through the integration of the cross-border service provider system and cross-border payment system, we provide valuable customer sources for financial institutions and provide customers with personalized cross-border comprehensive financial services featuring cross-border supply chain finance.",
  t36: "Log in",
  t37: "Register",
  t38: "Cooperation",
  t39: "Contact Us",
  t40: "MSO License",
  t41: "License Number",
  t42: "Check",
  t43: "Get started",
  t44: "Privacy Policy",
}
